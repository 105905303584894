<template>
<hr class="z-40">
<div class="z-40 text-md mt-4 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 text-gray-600 flex mb-5 flex-col sm:flex-row justify-between">
  <div class="flex flex-col sm:flex-row items-center">
    <div><img class="h-6" src="../assets/logo_compact.svg" alt=""></div>
    <div>
      <span class="text-indigo-600 pl-1 font-bold hover:underline cursor-pointer">xmeme</span> by
    </div>
    <div class="pl-1">
      <a href="https://twitter.com/gyankarn" target="_blank" class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-indigo-600 text-white">
        @gyankarn
      </a>
    </div>
  </div>
  <div class="flex flex-col sm:flex-row items-center">
    <a href="mailto:mail@karngyan.com">contact me</a>
  </div>
</div>
</template>

<script>
export default {
name: 'TheFooter'
}
</script>

<style scoped>

</style>
